import request from '@/utils/request'

/**
 * 微信授权登录
 * @param {*} code 微信code码
 */
export function login(code) {
  return request.post('/api/userInfo/wx/login', {
    code, // 微信授权code码
    type: 2 // 0.网页 1.小程序 2.微信公众号
  })
}

/**
 * 微信/手机注册
 * @param {Object} data 
 * @param {String} code 验证码
 * @param {String} phone 手机号码
 * @param 其他微信信息
 */
export function register(data) {
  // /wx/register 微信注册，必须传uinionid，用户表会保存微信信息
  // phoneRegister 接口只需要传手机号和验证码，不进行unionid的保存，用来微信外部收集用户信息
  let registerUrl = ''
  if(data.unionId) {
    registerUrl = '/api/userInfo/wx/register'
    data.type = 2 // 0 网页 1 小程序 2 公众号
    data.source = 1 // 1: 智慧云学堂 2 网页
  }
  else {
    registerUrl = '/api/userInfo/phoneLogin'
    data.type = 0 // 0 网页 1 小程序 2 公众号
    data.source = 2 // 1: 智慧云学堂 2 网页
  }
  return request.post(registerUrl, data)
}

/**
 * 获取用户信息
 * @param {*} userId 用户ID
 */
export function getInfo(userId) {
  return request.post('/api/userInfo/getUserInfo',  {
    userId
  })
}
/**
 * 抖音推广-互联网营销师报名
 * @param {*} code 
 * @param {*} sendState 是否发送企业微信通知：1.发送  2.不发送 默认发送
 * @returns 
 */
export function mgrCrm(data) {
  data.sendState = data.sendState ? data.sendState : 1
  return request.post('/api/mgrCrm/save', data)
}
/**
 * 获取手机验证码
 * @param {*} phone 手机号码
 */
export function sendCode(phone) {
  return request.post('/api/userInfo/sendAuthCode', {
    phone
  })
}
/**
 * 绑定手机号码
 * @param {*} phone 手机号码
 */
export function bindingPhone(data) {
  return request.post('/api/userInfo/bindingPhone', data)
}
/**
 * 公众号下单
 * @param {*} courseId 课程ID
 * @param {*} description 商品描述
 * @param {*} type 0.网页 1.小程序 2.微信公众号
 */
export function wxWebOrder(data) {
  const params = {type:2,...data}
  return request.post('/api/wx/wxWebOrder', params)
}
/**
 * 公众号大学生创造营下单
 * @param {*} courseId 课程ID
 * @param {*} description 商品描述
 * @param {*} type 0.网页 1.小程序 2.微信公众号
 */
export function wxUndergraduateOrder(data) {
  const params = {type:2,...data}
  return request.post('/api/wx/wxUndergraduateOrder', params)
}
/**
 * 获取用户报名信息
 */
export function getApplyInfo(data) {
  return request.post('/api/courseRegistration/getRegistrationInfo', data)
}
/**
 * 大学生创造营报名
 */
export function saveCampInfo(data) {
  // /api/undergraduateMember/saveInfo
  return request.post('/api/undergraduateMember/memberNo2/saveInfo', data)
}
/**
 * 获取大学生创造营报名信息
 */
export function getCampApplyInfo() {
  return request.post('/api/undergraduateMember/getRegistrationInfo', {
    courseId: '1234567891234567894'
  })
}
/**
 * 获取大学生创造营学校列表
 */
export function getCampSchoolList(type=1) {
  return request.post('/api/qrcode/getQrCodeList', {
    type
  })
}
/**
 * 大学生创造营个人转团队
 */
export function memberTurnTeam(data) {
  return request.post('/api/undergraduateMember/memberTurnTeam', data)
}
/**
 * 抖音H5下单支付
 * @param {*} courseId 课程ID
 * @param {*} description 商品描述
 * @param {*} ip 用户外网IP
 * @param {*} phone 手机号码
 * @param {*} type iOS、Android
 */
export function wxH5Order(data) {
  return request.post('/api/wx/wxH5Order', data)
}

/**
 * 简历基本信息保存
 */
export function saveBriefInfo(data) {
  return request.post('/api/briefInfo/saveBriefInfo', data)
}

/**
 * 简历基本信息修改
 */
 export function updateBriefInfo(data) {
  return request.post('/api/briefInfo/updateBriefInfo', data)
}

/**
 * 简历列表
 */
export function briefList() {
  return request.post('/api/briefInfo/briefInfoList')
}

/**
 * 简历-新增教育/工作等经历
 */
export function saveUndergoInfo(data) {
  return request.post('/api/undergoInfo/saveUndergoInfo', data)
}

/**
 * 简历-修改教育/工作等经历
 */
export function updateUndergoInfo(data) {
  return request.post('/api/undergoInfo/updateUndergoInfo', data)
}

/**
 * 简历-修改优势等
 */
export function updateBriefDetail(data) {
  return request.post('/api/undergoInfo/updateInfo', data)
}
/**
 * 简历-删除教育/工作等经历
 */
export function deleteUndergoInfo(data) {
  return request.post('/api/undergoInfo/deleteUndergoInfo', data)
}

/**
 * 通用H5下单支付
 * @param {*} courseId 课程ID
 * @param {*} description 商品描述
 * @param {*} ip 用户外网IP
 * @param {*} phone 手机号码
 * @param {*} pid
 * @param {*} cid
 * @param {*} aid
 * @param {Object} optional 其他字段
 * @param {*} type iOS、Android、wap
 */
 export function wxH5Pay(data) {
  data.optional = ''
  return request.post('/api/wx/wxH5Pay', data)
}

/**
 * 登出操作
 * @param {Number} type 1.只删token 2.用户和token都删
 * @param {String} userId
 * @returns 
 */
export function logout(data) {
 return request.post('/api/userInfo/wx/out', data)
}

/**
 * 支付宝H5下单支付
 * @param {*} courseId 课程ID
 * @param {*} description 商品描述
 * @param {*} ip 用户外网IP
 * @param {*} phone 手机号码
 * @param {*} pid
 * @param {*} cid
 * @param {*} aid
 * @param {Object} optional 其他字段
 * @param {*} type iOS、Android、wap
 */
 export function aliH5Pay(data) {
  data.optional = ''
  return request.post('/api/ali/aliPayH5', data)
}
/**
 * 微信外部H5购买课程下单
 * @param {*} courseId 课程ID
 * @param {*} description 商品描述
 * @param {*} ip 用户外网IP
 * @param {*} phone 手机号码
 * @param {*} type iOS、Android
 */
 export function wxCourseOrder(data) {
  return request.post('/api/wx/wxWebH5Order', data)
}

/**
 * 跳转到小程序联系客服的链接
 * @param {String} source // lesson: 试听课 cert 证书
 * @returns 
 */
 export function getMiNiUrl(source) {
  return request.post('/api/wxQrcodeMgr/getMiNiUrl/' + source)
 }

/**
 * 淘宝创造营报名
 */
 export function saveTBCampInfo(data) {
  return request.post('/api/tb618/addTb618', data)
}
