import { getToken, setToken, removeToken, getUserId, setUserId, removeUserId, getUserInfo, setUserInfo, removeUserInfo } from '@/utils/auth'
import { login, register, getInfo, getApplyInfo } from '@/api/user'

const state = {
  token: getToken(),
  userId: getUserId(),
  userInfo: getUserInfo(),
  applyInfo: null
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_USERID: (state, userId) => {
    state.userId = userId
  },
  SET_APPLYINFO: (state, applyInfo) => {
    state.applyInfo = applyInfo
  }
}

const actions = {
  // user login
  login({ commit, dispatch }, code) {
    return new Promise((resolve, reject) => {
      login(code).then(response => {
        if(!response) {
          resolve(response)
        }
        const { userId } = response
        const userInfo = typeof(response)==='object' ? response : {}
        commit('SET_USERINFO', response)
        setUserInfo(userInfo)
        if(!!userId) {
          const token = `Bearer ${response.token}`
          commit('SET_TOKEN', token)
          commit('SET_USERID', userId)
          setUserId(userId)
          setToken(token)
        }
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // user register
  register({ commit, state }, data) {
    const userInfo = state.userInfo || {}
    if(userInfo.unionid) {
      userInfo.headImgUrl = userInfo.avatar
      userInfo.unionId = userInfo.unionid
    }
    const params = {
      ...userInfo,
      ...data,
    }
    return new Promise((resolve, reject) => {
      register(params).then(response => {
        if(!response) {
          resolve(response)
        }
        const { userId } = response
        const token = `Bearer ${response.token}`
        const userInfo = typeof(response)==='object' ? response : {}
        commit('SET_TOKEN', token)
        commit('SET_USERID', userId)
        commit('SET_USERINFO', response)
        setUserId(userId)
        setToken(token)
        setUserInfo(userInfo)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.userId).then(response => {
        const userInfo = typeof(response)==='object' ? response : {}
        commit('SET_USERINFO', userInfo)
        setUserInfo(userInfo)
        resolve(userInfo)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 获取用户报名表信息
  getApplyInfo({ commit }, courseId) {
    return new Promise((resolve, reject) => {
      getApplyInfo({
        courseId
      }).then(response => {
        commit('SET_APPLYINFO', response)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        commit('SET_USERID', '')
        commit('SET_USERINFO', '')
        removeUserId()
        removeToken()
        removeUserInfo()
        resolve()
    })
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
