import router from './router'
import store from './store'
import getPageTitle from '@/utils/get-page-title'

import { Notify } from 'vant'

const loginList = ['/course', '/studentcourse', '/order/apply', '/userinfo', '/order/user', '/resume/info', '/resume/list', '/resume/add', '/camp/apply3', '/camp/member', '/camp/list', '/actcert', '/actlecture', '/actcamp1', '/actcertcamp'] // need wxlogin list
const mobileWhiteList = ['/course', '/studentcourse', '/actcert', '/actlecture', '/actcamp1', '/actcertcamp'] // 需要登录但是不需要验证手机的页面
const wxWhiteList = ['/course', '/studentcourse', '/order/apply', '/order/user', '/camp/apply3', '/camp/member', '/actcert', '/actlecture', '/actcamp1', '/actcertcamp'] // 需要判断微信和外部浏览器登录的页面

router.beforeEach(async(to, from, next) => {
  document.title = getPageTitle(to.meta.title) // set page title
  const ua = window.navigator.userAgent.toLowerCase()
  if(ua.indexOf("dingtalk")>-1) {
    dd.ready(function() {
      dd.biz.navigation.setTitle({
        title: getPageTitle(to.meta.title), 
      })
    })
  }
  const ignoreWx = wxWhiteList.indexOf(to.path) > -1 && ua.indexOf("micromessenger")===-1 // 是否微信外需要登录的页面
  // 判断是否需要登录
  if(loginList.indexOf(to.path) !== -1) {
    const pageQuery = {
      ...to.query,
      redirect: to.path
    }
    const { userInfo, token, userId } = store.getters
    if(!!userInfo && !!token && !!userId) {
      // userInfo/token/userId都存在时，直接从本地内存获取用户信息（避免cookie失效时userInfo还存在）
      if(ignoreWx) {
        // 非微信直接跳转
        next()
        return
      }
      if(!userInfo?.userId && to.path!='/register' && mobileWhiteList.indexOf(to.path) === -1) {
        next({
          replace: true,
          path: '/register',
          query: pageQuery
        })
        return
      }
      if(!userInfo?.phone && to.path!='/userinfo' && mobileWhiteList.indexOf(to.path) === -1) {
        next({
          replace: true,
          path: '/userinfo',
          query: pageQuery
        })
        return
      }
      next()
      return
    }
    // 非微信直接到注册页面进行手机注册或登录
    if(ignoreWx && mobileWhiteList.indexOf(to.path) === -1) {
      next({
        replace: true,
        path: '/register',
        query: pageQuery
      })
      return
    }
    if(ignoreWx && mobileWhiteList.indexOf(to.path) > -1) {
      next()
      return
    }
    const code = to.query.code
    if(!!code) {
      // 存在code，用code换取用户信息
      store.dispatch('user/login', code).then((userInfo)=>{
        if(!userInfo?.userId && mobileWhiteList.indexOf(to.path) === -1) {
          next({
            replace: true,
            path: '/register',
            query: pageQuery
          })
          return
        }
        if(!userInfo?.phone && mobileWhiteList.indexOf(to.path) === -1) {
          next({
            replace: true,
            path: '/userinfo',
            query: pageQuery
          })
          return
        }
        next()
      }).catch(error=>{
        if(error.resultCode == 2 || error.resultCode == 900002) {
          return
        }
        Notify({ type: 'danger', message: '服务器异常，请稍后再试' });
      })
    }
    else {
      // 没有code进行微信授权
      let redirectURL = encodeURIComponent(window.location.origin + window.location.pathname + window.location.search)
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa3bb1ed7a861ba06&redirect_uri='+redirectURL+'&response_type=code&scope=snsapi_userinfo&state=register#wechat_redirect'
    }
  }
  else {
    next()
  }
})
