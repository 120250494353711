import Vue from 'vue';
import VueRouter from 'vue-router';
import App from '@/App.vue';

const routes = [
  {
    path: '/',
    component: App,
    children: [
      {
        path: '/',
        meta: {
          title: '中思智数',
        },
        name: 'Home',
        component: () => import('@/views/home/indexWx.vue'),
      },
      {
        path: '/logout',
        meta: {
          title: '中思智数',
        },
        name: 'Logout',
        component: () => import('@/views/logout.vue'),
      },
      {
        path: '/register',
        meta: {
          title: '绑定手机',
        },
        name: 'Register',
        component: () => import('@/views/user/info.vue'),
      },
      {
        path: '/userinfo',
        meta: {
          title: '绑定手机',
        },
        name: 'UserInfo',
        component: () => import('@/views/user/info.vue'),
      },
      {
        path: '/order/apply',
        meta: {
          title: '互联网营销师报名表',
        },
        name: 'Apply',
        component: () => import('@/views/apply/index.vue'),
      },
      {
        path: '/course',
        meta: {
          title: '互联网营销师',
          backgroundColor: '#3A51DC'
        },
        name: 'CourseInfo',
        component: () => import('@/views/course/index.vue'),
      },
      {
        path: '/studentcourse', // 针对学生直接付款的链接
        meta: {
          title: '互联网营销师',
        },
        name: 'StudentCourseInfo',
        component: () => import('@/views/course/index.vue'),
      },
      {
        path: '/contact',
        meta: {
          title: '添加邀请',
        },
        name: 'Contact',
        component: () => import('@/views/contact/index.vue'),
      },
      {
        path: '/activitycontact',
        meta: {
          title: '互联网营销师',
        },
        name: 'ActivityContact',
        component: () => import('@/views/contact/activity.vue'),
      },
      {
        path: '/actmanager',
        meta: {
          title: '互联网营销师',
          desc: '百度投放'
        },
        name: 'ActivityManager',
        component: () => import('@/views/activity/manager0105.vue'),
      },
      {
        path: '/actcert',
        meta: {
          title: '互联网营销师',
          backgroundColor: '#251477',
          desc: '互联网营销师证书导向9.9元'
        },
        name: 'ActivityManagerCert',
        component: () => import('@/views/activity/managerCert.vue'),
      },
      {
        path: '/actcert1',
        meta: {
          title: '互联网营销师',
          backgroundColor: '#251477',
          desc: '互联网营销师证书导向1元'
        },
        name: 'ActivityManagerCert1',
        component: () => import('@/views/activity/managerCert.vue'),
      },
      {
        path: '/actlecture',
        meta: {
          title: '互联网营销师训练营',
          backgroundColor: '#251477',
          desc: '互联网营销师试听课9.9元'
        },
        name: 'ActivityManagerLecture',
        component: () => import('@/views/activity/managerLecture.vue'),
      },
      {
        path: '/actlecture1',
        meta: {
          title: '互联网营销师训练营',
          backgroundColor: '#251477',
          desc: '互联网营销师试听课1元'
        },
        name: 'ActivityManagerLecture1',
        component: () => import('@/views/activity/managerLecture.vue'),
      },
      {
        path: '/actcamp1',
        meta: {
          title: '3天互联网营销师实操训练营',
          backgroundColor: '#277AFF',
          desc: '3天互联网营销师实操训练营'
        },
        name: 'ActivityManagerCamp',
        component: () => import('@/views/activity/managerCamp.vue'),
      },
      {
        path: '/actcertcamp',
        meta: {
          title: '互联网营销师考证训练营',
          backgroundColor: '#ED5221',
          desc: '互联网营销师考证训练营'
        },
        name: 'ActivityManagerCamp2',
        component: () => import('@/views/activity/managerCamp2.vue'),
      },
      {
        path: '/order/user',
        meta: {
          title: '订单信息',
        },
        name: 'UserOrder',
        component: () => import('@/views/user/order.vue'),
      },
      {
        path: '/camp',
        meta: {
          title: '大学生电商营销创造营',
          backgroundColor: '#f6f6f6'
        },
        name: 'CampIntro',
        component: () => import('@/views/camp/index.vue'),
      },
      {
        path: '/camp/apply',
        meta: {
          title: '大学生电商营销创造营',
          backgroundColor: '#f6f6f6'
        },
        name: 'CampApply',
        component: () => import('@/views/camp/index.vue'),
      },
      {
        path: '/camp/apply3',
        meta: {
          title: '大学生电商营销创造营',
          backgroundColor: '#f6f6f6'
        },
        name: 'CampApply3',
        component: () => import('@/views/camp/index.vue'),
      },
      {
        path: '/camp/member',
        meta: {
          title: '大学生电商营销创造营',
          backgroundColor: '#f6f6f6'
        },
        name: 'CampMember',
        component: () => import('@/views/camp/member.vue'),
      },
      {
        path: '/camp/member/:id',
        meta: {
          title: '大学生电商营销创造营',
          backgroundColor: '#f6f6f6'
        },
        name: 'CampMemberEdit',
        component: () => import('@/views/camp/member.vue'),
      },
      {
        path: '/order/camp',
        meta: {
          title: '大学生电商营销创造营',
        },
        name: 'CampOrder',
        component: () => import('@/views/camp/order.vue'),
      },
      {
        path: '/camp/school',
        meta: {
          title: '大学生电商营销创造营',
        },
        name: 'CampSchool',
        component: () => import('@/views/camp/school.vue'),
      },
      {
        path: '/camp/ding',
        meta: {
          title: '大学生电商营销创造营第二期',
          backgroundColor: '#1a1a1a'
        },
        name: 'CampDing',
        component: () => import('@/views/camp/dingcode.vue'),
      },
      {
        path: '/camp/intro',
        meta: {
          title: '大学生电商营销创造营第二期',
          backgroundColor: '#435ae8'
        },
        name: 'CampIntro2',
        component: () => import('@/views/camp/intro2.vue'),
      },
      {
        path: '/camp/intro3',
        meta: {
          title: '大学生电商营销创造营第三期',
          backgroundColor: '#435ae8'
        },
        name: 'CampIntro3',
        component: () => import('@/views/camp/intro3.vue'),
      },
      {
        path: '/camp/list',
        meta: {
          title: '我的报名表',
          backgroundColor: '#3A51DC'
        },
        name: 'CampMemberList',
        component: () => import('@/views/camp/list.vue'),
      },
      {
        path: '/camp/turn',
        meta: {
          title: '大学生电商营销创造营'
        },
        name: 'CampTurnTeam',
        component: () => import('@/views/camp/turnteam.vue'),
      },
      {
        path: '/activity/streaming',
        meta: {
          title: '互联网营销师',
        },
        name: 'streamIndex',
        component: () => import('@/views/activity/streaming.vue'),
      },
      {
        path: '/cvslink',
        meta: {
          title: '转换链接',
        },
        name: 'ConversionLink',
        component: () => import('@/views/aliPub/conversionLink.vue'),
      },
      {
        path: '/recruitment',
        meta: {
          title: '中思智数招聘',
          backgroundColor: '#2761FE'
        },
        name: 'Recruitment',
        component: () => import('@/views/activity/recruitment.vue'),
      },
      {
        path: '/resume/list',
        meta: {
          title: '简历小助手',
          backgroundColor: '#f8f8f8'
        },
        name: 'ResumeList',
        component: () => import('@/views/resume/list.vue'),
      },
      {
        path: '/resume/add',
        meta: {
          title: '简历小助手'
        },
        name: 'ResumeApply',
        component: () => import('@/views/resume/apply.vue'),
      },
      {
        path: '/resume/info',
        meta: {
          title: '简历小助手'
        },
        name: 'ResumeInfo',
        component: () => import('@/views/resume/info.vue'),
      },
      {
        path: '/resume/edit/:type',
        meta: {
          title: '简历小助手'
        },
        name: 'ResumeEdit',
        component: () => import('@/views/resume/detailApply.vue'),
      },
      {
        path: '/resume/preview',
        meta: {
          title: '简历小助手'
        },
        name: 'ResumePreview',
        component: () => import('@/views/resume/info.vue'),
      },
      {
        path: '/paysuccess',
        meta: {
          title: '报名成功'
        },
        name: 'PaySuccess',
        component: () => import('@/views/paysuccess/index.vue'),
      },
      {
        path: '/tbcamp',
        meta: {
          title: '潜力淘客加速营6月',
          backgroundColor: '#FF5A00'
        },
        name: 'TBCampIndex',
        component: () => import('@/views/taobaoCamp/index.vue'),
      },
      {
        path: '/tbcamp/apply',
        meta: {
          title: '潜力淘客加速营6月',
          backgroundColor: '#FF5A00'
        },
        name: 'TBCampApply',
        component: () => import('@/views/taobaoCamp/member.vue'),
      },
      {
        path: '/tbcamp/success',
        meta: {
          title: '潜力淘客加速营6月'
        },
        name: 'TBCampSuccess',
        component: () => import('@/views/taobaoCamp/success.vue'),
      },
      {
        path: '*',
        redirect: '/'
      },
    ],
  },
];
Vue.use(VueRouter);
export default new VueRouter({ mode: 'history', routes });