import axios from 'axios'
import { Notify } from 'vant'
import { delUrlParmas } from '@/utils'
import store from '@/store'
import router from '@/router';

const request = axios.create({
  headers: {
    'content-type': 'application/json;utf-8'
  },
});
request.interceptors.request.use(
  config => {
    // do something before request is sent
    const token = store.getters.token || ''
    const userId = store.getters.userId || ''
    if (token && userId) {
      config.headers['Authorization'] = token
      config.headers['userId'] = userId
    }
    // 无参数时要传一个空的json对象
    if(typeof(config.data)==='undefined') {
      config.data = {}
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
request.interceptors.response.use((resp) => {
  // resultCode  0: 正常； 2: token失效；900002：微信code失效；其他提示错误信息
  const { data, resultCode, resultMsg} = resp.data
  if (resultCode == 0) {
    return Promise.resolve(data);
  } else if (resultCode == 2 || resultCode == 900002) {
    // token失效清除本地存储
    store.dispatch('user/logout').then(()=>{
      const ua = window.navigator.userAgent.toLowerCase()
      let redirectURL = delUrlParmas(['code', 'state'])
      if(ua.indexOf("micromessenger")===-1 || resp.config.ali) { // 非微信浏览器直接到注册页面 || 淘宝联盟token失效跳到手机绑定页面
        const pageQuery = {
          ...router.currentRoute.query,
          redirect: router.currentRoute.path
        }
        router.replace({
          path: '/register',
          query: pageQuery
        })
      }
      else {  // 微信
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa3bb1ed7a861ba06&redirect_uri='+redirectURL+'&response_type=code&scope=snsapi_userinfo&state=register#wechat_redirect'
      }
    })
    return Promise.reject(resp.data);
  } else {
    if(!resp.config.customError) {
      Notify({ type: 'danger', message: resultMsg })
    }
    return Promise.reject(resp.data);
  }
});
export default request;