import Vue from 'vue';
import router from './router';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import Vant from 'vant'; //移动端UI框架
import 'vant/lib/index.css';
Vue.use(Vant);

import request from './utils/request';
import './permission' // permission control
import store from './store'
// import vConsole from 'vconsole';

// new vConsole();
import vueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.min.css'
Vue.use(vueAwesomeSwiper)
Vue.use(ElementUI, { size: 'small' });
import VueClipboard from 'vue-clipboard2'
import vueToPdf from 'vue-to-pdf';
Vue.use(vueToPdf);
Vue.use(VueClipboard)
Vue.prototype.$request = request;

new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h('router-view'),
});
