import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

const modulesFiles = import.meta.globEager("./modules/*.js") 
const modules = {}
for (const key in modulesFiles) {
  modules[key.replace(/(\.\/modules\/|\.js)/g, '')] = modulesFiles[key].default
}

Object.keys(modules).forEach(item => {
  modules[item]['namespaced'] = true
})

const store = new Vuex.Store({
  modules,
  getters
})

export default store
