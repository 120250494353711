import Cookies from 'js-cookie'

const TokenKey = 'Token'
const UserIdKey = 'UserId'
const UserInfoKey = 'UserInfo'
const expires = 1 // 过期时间 单位天

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { expires })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUserId() {
  return Cookies.get(UserIdKey)
}

export function setUserId(userId) {
  return Cookies.set(UserIdKey, userId, { expires })
}

export function removeUserId() {
  return Cookies.remove(UserIdKey)
}

export function getUserInfo() {
  return JSON.parse(localStorage.getItem(UserInfoKey))
}

export function setUserInfo(userInfo) {
  return localStorage.setItem(UserInfoKey, JSON.stringify(userInfo))
}

export function removeUserInfo() {
  return localStorage.removeItem(UserInfoKey)
}
