<template>
  <div id="app" :style="{'background-color': backgroundColor}">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
    };
  },
  computed: {
    backgroundColor() {
      return this.$route?.meta?.backgroundColor || '#fff'
    }
  },
  methods: {
  }
};
</script>

<style>
html, body, #app {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
</style>
